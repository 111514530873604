import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function Graph({ data, type }) {
  if (type === "meals") {
    return (
      <div style={{ marginTop: "40px" }}>
        {data && data !== [] ? (
          <>
            <h3 style={{ marginBottom: "30px" }}>Meal Data</h3>
            <ResponsiveContainer width="100%" height={500}>
              <LineChart
                data={data}
                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
              >
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="Break Fast Count"
                  stroke="green"
                />
                <Line type="monotone" dataKey="Lunch Count" stroke="red" />
                <Line type="monotone" dataKey="Snacks Count" stroke="blue" />
                <Line type="monotone" dataKey="Dinner Count" stroke="orange" />
              </LineChart>
            </ResponsiveContainer>
          </>
        ) : (
          <p></p>
        )}
      </div>
    );
  } else {
    return (
      <div style={{ margin: "40px 0px" }}>
        {data && data !== [] ? (
          <>
            <h3 style={{ marginBottom: "30px" }}>Students Number Data</h3>
            <ResponsiveContainer width="100%" height={500}>
              <LineChart
                data={data}
                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
              >
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="blue" />
              </LineChart>
            </ResponsiveContainer>
          </>
        ) : (
          <p></p>
        )}
      </div>
    );
  }
}

export default Graph;
