import { Paper } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { UserContext } from "../../providers/UserProvider";

function ByDate(props) {
  const { user } = useContext(UserContext);
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (user === null) {
      // props.toastCall("Log in first", "error");
      history.push("/admin");
    } else if (user !== undefined) {
      if (user.admin === false) {
        props.toastCall("This user do not have access", "warning");
        history.push("/admin");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.admin) {
        axios
          .get(`${process.env.REACT_APP_SERVER_ADDRESS}/admin/distribution`, {
            headers: { Authorization: `BEARER ${user.admin.Aa}` },
          })
          .then((res) => {
            // const arr = [];
            // for (let index = 0; index < 100; index++) {
            //   arr.push(res.data.message[index]);
            // }
            // console.log(arr);
            setData(res.data.message);
          })
          .catch((err) => console.log(err));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Paper style={{ padding: "20px" }}>
      {data && data !== [] ? (
        <>
          <h3 style={{ marginBottom: "30px" }}>
            Number of students getting fully vaccinated by date
          </h3>
          <ResponsiveContainer width="100%" height={500}>
            <LineChart
              // width={1400}
              // height={500}
              data={data}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Line type="monotone" dataKey="count" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="pukkaSecondDose" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </>
      ) : (
        <p>Loading ...</p>
      )}
    </Paper>
  );
}

export default ByDate;
