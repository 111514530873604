import React, { useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { Avatar, Button, makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import GoogleIcon from "../../images/GoogleIcon.svg";

import "./index.css";
import { signInWithGoogle, logOut } from "../../services/firebase";
import { UserContext } from "../../providers/UserProvider";
import { ToastContainer } from "react-toastify";
import { bounce } from "../../App";

import Dashboard from "./Dashboard";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// const AllPages = [
//   {
//     View: lazy(() => import("./Home")),
//     path: "/admin/home",
//     exact: "true",
//   },
//   {
//     View: lazy(() => import("./OnCampusHostelWise")),
//     path: "/admin/oncampushostel",
//     exact: "true",
//   },
//   {
//     View: lazy(() => import("./OnCampusBatchWise")),
//     path: "/admin/oncampusbatch",
//     exact: "true",
//   },
//   // {
//   //   View: lazy(() => import("./Dashboard")),
//   //   path: "/admin/bydate",
//   //   exact: "true",
//   // },
// ];

function Index(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      if (user.admin !== undefined) {
        const { admin } = user;
        if (admin === false) {
          props.toastCall("This user do not have access", "warning");
          logOut();
        } else {
          history.push("/admin/dashboard");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="admin-main">
      <Route path="/admin" exact={true}>
        <div className="admin-next">
          <Paper className="admin-paper" elevation={24}>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <PermIdentityIcon />
              </Avatar>
              <Typography>Log in with google</Typography>
              <div style={{ padding: "15px" }} />
              <form className={classes.form} noValidate>
                <center>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className={classes.submit}
                    onClick={(e) => {
                      e.preventDefault();
                      user ? logOut() : signInWithGoogle();
                    }}
                    startIcon={
                      <img
                        style={{ height: "1rem", width: "1rem" }}
                        src={GoogleIcon}
                        alt="Sign In with Google"
                      />
                    }
                  >
                    {user ? "Log Out" : "Log In"}
                  </Button>
                </center>
              </form>
            </div>
            <ToastContainer transition={bounce} />
          </Paper>
        </div>
      </Route>
      <Route path="/admin/dashboard" exact={true}>
        <Dashboard toastCall={props.toastCall} setLoading={props.setLoading} />
      </Route>
    </div>
  );
}

export default Index;
