import dotenv from "dotenv";
import firebase from "firebase/app";
import "firebase/auth";

import { toastCall } from "../App";

dotenv.config();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth(app);

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = async () => {
  await auth
    .signInWithPopup(googleProvider)
    .then((res) => {
      if (!res.user.email.includes("iith.ac.in")) {
        toastCall("Please log in with iith mail id", "error");
        logOut("noToast");
        return;
      }
      toastCall("Successfully logged in", "success");
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const logOut = async (noToast) => {
  await auth
    .signOut()
    .then(() => {
      !noToast && toastCall("Successfully logged out", "info");
    })
    .catch((error) => {
      console.log(error.message);
    });
};
