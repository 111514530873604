import React, { useContext, useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import Axios from "axios";

import Dashboard from "../../components/Dashboard";
import { useHistory } from "react-router";
import { UserContext } from "../../providers/UserProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 300,
    zIndex: 1000,
  },
  backdrop: {
    width: "100%",
    height: "100%",
  },
}));

const HostelMenu = (Props) => {
  const classes = useStyles();

  useEffect(() => {
    return () => window.sessionStorage.removeItem("hostel");
  }, []);

  const handleClick = (Hostel) => {
    Props.setCurrentHostel(Hostel);
    window.sessionStorage.setItem("hostel", Hostel);
  };

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <Button
            size="large"
            endIcon={<ArrowDropDownIcon />}
            variant="contained"
            color="primary"
            {...bindTrigger(popupState)}
          >
            {Props.currentHostel}
          </Button>
          <Menu
            PopoverClasses={{ paper: classes.paper }}
            onClick={popupState.close}
            {...bindMenu(popupState)}
          >
            {Props.hostels.map((Page) => (
              <MenuItem key={Page} onClick={() => handleClick(Page)}>
                <Typography noWrap>Hostel {Page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </PopupState>
  );
};

function OnCampusHostel(props) {
  const [hostels, setHostels] = useState([]);
  const [hostelData, setHostelData] = useState([]);
  const [currentHostel, setCurrentHostel] = useState(
    `Hostel ${window.sessionStorage.getItem("hostel")}` ||
      `Hostel ${hostels[0]}`
  );
  const [total, setTotal] = useState(0);
  const [doseData, setDoseData] = useState([]);
  const [vaccineData, setVaccineData] = useState([]);
  const [vaccineNames, setVaccineNames] = useState([]);
  const { user } = useContext(UserContext);

  const history = useHistory();

  useEffect(() => {
    setCurrentHostel(hostels[0]);
  }, [hostels]);

  useEffect(() => {
    if (user === null) {
      // props.toastCall("Log in first", "error");
      history.push("/admin");
    } else if (user !== undefined) {
      if (user.admin === false) {
        props.toastCall("This user do not have access", "warning");
        history.push("/admin");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.admin) {
        Axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/hostels`)
          .then((res) => setHostels(res.data.message))
          .catch((err) => console.log(err));
        Axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/admin/hosteldata`, {
          headers: { Authorization: `BEARER ${user.admin.Aa}` },
        })
          .then((res) => {
            setHostelData(res.data.message);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [user]);

  useEffect(() => {
    if (hostelData !== []) {
      hostelData.map((hostel) => {
        if (hostel.hostel === currentHostel) {
          setTotal(hostel.total);
          setDoseData([hostel.unvaccinated, hostel.dose1, hostel.dose2]);
          setVaccineData(hostel.vaccineData.map((vaccine) => vaccine.count));
          setVaccineNames(hostel.vaccineData.map((vaccine) => vaccine.vaccine));
        }
        return 0;
      });
    }
  }, [currentHostel, hostelData]);

  return (
    <>
      <HostelMenu
        hostels={hostels}
        currentHostel={currentHostel}
        setCurrentHostel={setCurrentHostel}
      />
      <Dashboard
        vaccineNames={vaccineNames}
        vaccineData={vaccineData}
        doseData={doseData}
        total={total}
      />
    </>
  );
}

export default OnCampusHostel;
