import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  Avatar,
  Button,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import BarcodeReader from "react-barcode-reader";
import Axios from "axios";

import GoogleIcon from "../../images/GoogleIcon.svg";
import { UserContext } from "../../providers/UserProvider";
import { logOut } from "../../services/firebase";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Home(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [barCodeResult, setBarCodeResult] = useState(null);
  const [vaccinationData, setVaccinationData] = useState();

  useEffect(() => {
    if (user === null) {
      // props.toastCall("Log in first", "error");
      history.push("/adminscan");
    } else if (user !== undefined) {
      if (user.admin === false) {
        props.toastCall("This user do not have access", "warning");
        history.push("/adminscan");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.admin) {
        if (barCodeResult !== null) {
          Axios.post(
            `${process.env.REACT_APP_SERVER_ADDRESS}/admin/scan`,
            { email: `${barCodeResult.toLowerCase()}@iith.ac.in` },
            {
              headers: { Authorization: `BEARER ${user.admin.Aa}` },
            }
          )
            .then(async (res) => {
              setVaccinationData(res.data.message);
              props.toastCall(
                res.data.message,
                res.data.success ? "success" : "error"
              );
            })
            .catch((err) => props.toastCall(err, "error"));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCodeResult]);

  const handleScan = (data) => {
    setBarCodeResult(data);
  };

  const handleError = (err) => {
    console.log(err);
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <PermIdentityIcon />
      </Avatar>
      {user && <Typography>Scan the bar code</Typography>}
      <div style={{ padding: "15px" }} />
      <form className={classes.form} noValidate>
        {user ? (
          <div>
            <BarcodeReader onError={handleError} onScan={handleScan} />
            <p>
              {barCodeResult === null
                ? "Please scan the barcode to display the result here"
                : barCodeResult}
            </p>
            {barCodeResult ? (
              vaccinationData === null ? (
                <p>Email Not Found</p>
              ) : vaccinationData && vaccinationData.namematch === 1 ? (
                <List>
                  <ListItem>Name : {vaccinationData.Name}</ListItem>
                  <ListItem>Vaccine Name : {vaccinationData.vaccine}</ListItem>
                  <ListItem>
                    Date of Vaccination :{" "}
                    {moment(vaccinationData.vaccinedate).format(
                      "MMMM Do, YYYY"
                    )}
                  </ListItem>
                  <ListItem>Number of doses : {vaccinationData.dose}</ListItem>
                </List>
              ) : (
                <p>Verification Status Pending</p>
              )
            ) : (
              <p></p>
            )}
          </div>
        ) : (
          <p>Please log in first</p>
        )}
      </form>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
        onClick={async (e) => {
          e.preventDefault();
          if (user) {
            await logOut();
            history.push("/adminscan");
          } else history.push("/adminscan");
        }}
        startIcon={
          <img
            style={{ height: "1rem", width: "1rem" }}
            src={GoogleIcon}
            alt="Sign In with Google"
          />
        }
      >
        {user ? "Log Out" : "Log In"}
      </Button>
    </div>
  );
}

export default Home;
