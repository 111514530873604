import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router";
import { useConfirm } from "material-ui-confirm";
import { UserContext } from "../../providers/UserProvider";
import { logOut } from "../../services/firebase";
import GoogleIcon from "../../images/GoogleIcon.svg";
import axios from "axios";
import { b64toBlob } from "../../components/scripts";
import { MealMenu } from "../MessScanPages/Home";
import Graph from "./Graph";
import { ToastContainer } from "react-toastify";
import { bounce } from "../../App";
import MaterialTable from "material-table";
import materialTableIcons from "../../components/tableIcons";

const columns = [
  {
    title: "Name",
    field: "accountname",
    editable: "never",
  },
  {
    title: "Email",
    field: "email",
    editable: "never",
  },
  {
    title: "On Campus",
    field: "onCampus",
    lookup: {
      1: "Yes",
      0: "No",
    },
  },
  {
    title: "Mess Fee Paid",
    field: "messPaid",
    lookup: {
      1: "Yes",
      0: "No",
    },
  },
  {
    title: "Mess Registration",
    field: "messReg",
    lookup: {
      null: "Not Registered",
      MessA: "MessA",
      MessB: "MessB",
    },
  },
  {
    title: "Breakfast Registration",
    field: "breakReg",
    lookup: {
      null: "Not Registered",
      A: "A",
      B: "B",
      C: "C",
      D: "D",
    },
  },
  {
    title: "Lunch Registration",
    field: "lunchReg",
    lookup: {
      null: "Not Registered",
      A: "A",
      B: "B",
      C: "C",
      D: "D",
    },
  },
];

function Dashboard(props) {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const confirm = useConfirm();
  const [currentMeal, setCurrentMeal] = useState({ id: 0, meal: "Breakfast" });
  const [messCounts, setMessCounts] = useState([]);
  const [studentsDetails, setStudentsDetails] = useState([]);
  const [mess1, setMess1] = useState();
  const [mess2, setMess2] = useState();
  const [mess3, setMess3] = useState();
  const [mess4, setMess4] = useState();
  const [update, setUpdate] = useState(0);

  const today = useMemo(() => {
    let today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }, []);

  const [date, setDate] = useState(today);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [data, setData] = useState([]);
  const [minutesSplit, setMinutesSplit] = useState([]);

  // const [dummyData, setDummyData] = useState([
  //   {
  //     accountname: "MANDADI AKSHITHA",
  //     breakReg: "B",
  //     contact: null,
  //     email: "ai19btech11001@iith.ac.in",
  //     lunchReg: "B",
  //     messPaid: 1,
  //     messReg: null,
  //     onCampus: 0,
  //   },
  //   {
  //     accountname: "GANESH BOMBATKAR",
  //     breakReg: "B",
  //     contact: null,
  //     email: "cs20btech11016@iith.ac.in",
  //     lunchReg: "B",
  //     messPaid: 1,
  //     messReg: null,
  //     onCampus: 0,
  //   },
  //   {
  //     accountname: "Greeshma Shanavas P",
  //     breakReg: "B",
  //     contact: null,
  //     email: "bo22resch01001@iith.ac.in",
  //     lunchReg: "C",
  //     messPaid: 1,
  //     messReg: "LDH",
  //     onCampus: 1,
  //   },
  //   {
  //     accountname: "Antonio Jr Fabela Regis",
  //     breakReg: "A",
  //     contact: null,
  //     email: "cc21resch16001@iith.ac.in",
  //     lunchReg: "A",
  //     messPaid: 1,
  //     messReg: "UDH",
  //     onCampus: 1,
  //   },
  // ]);

  useEffect(() => {
    if (user === null) {
      // props.toastCall("Log in first", "error");
      history.push("/messadmin");
    } else if (user !== undefined) {
      if (user.messAdmin === false) {
        props.toastCall("This user do not have access", "warning");
        history.push("/messadmin");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.messAdmin) {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/messdatadates`,
            {
              headers: { Authorization: `BEARER ${user.messAdmin.Aa}` },
              params: {
                start: startDate,
                end: endDate,
              },
            }
          )
          .then((res) => {
            const data = res.data.message;
            const dates = [...new Set(data.map(({ mealDate }) => mealDate))];
            const newDates = {};
            // eslint-disable-next-line array-callback-return
            dates.map((date) => {
              newDates[`${date}`] = {
                bfCount: null,
                lunchCount: null,
                snacksCount: null,
                dinnerCount: null,
              };
            });
            // eslint-disable-next-line array-callback-return
            data.map((item) => {
              const found = Object.keys(newDates).find(
                (date) => date === item.mealDate
              );
              if (item.slot === 0) newDates[found].bfCount = item.count;
              else if (item.slot === 1) newDates[found].lunchCount = item.count;
              else if (item.slot === 2)
                newDates[found].snacksCount = item.count;
              else if (item.slot === 3)
                newDates[found].dinnerCount = item.count;
            });
            let newData = [];
            Object.keys(newDates).forEach((date) => {
              newData.push({
                date,
                "Break Fast Count": newDates[date].bfCount,
                "Lunch Count": newDates[date].lunchCount,
                "Snacks Count": newDates[date].snacksCount,
                "Dinner Count": newDates[date].dinnerCount,
              });
            });
            setData(newData);
          })
          .catch((err) => console.log(err));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, startDate, endDate]);

  useEffect(() => {
    if (user) {
      if (user.messAdmin) {
        axios
          .get(`${process.env.REACT_APP_SERVER_ADDRESS}/student/messcounts`, {
            headers: {
              Authorization: `BEARER ${user.messAdmin.Aa}`,
            },
          })
          .then((res) => {
            setMessCounts(res.data.message);
            props.setLoading(false);
          })
          .catch((err) => {
            props.setLoading(false);
            console.log("err ,", err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, update]);

  useEffect(() => {
    if (user) {
      if (user.messAdmin) {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/minutessplit`,
            {
              headers: { Authorization: `BEARER ${user.messAdmin.Aa}` },
              params: {
                slot: currentMeal.id,
                date,
              },
            }
          )
          .then((res) => {
            const data = res.data.message;
            if (data.length !== 1) {
              const newData = data.map((item) => {
                return {
                  time: `${item.Hour}:${item.Minute.toString().length !== 1
                    ? item.Minute
                    : "0" + item.Minute
                    }`,
                  count: item.Count,
                };
              });
              setMinutesSplit(newData);
            } else setMinutesSplit([]);
          });
      }
    }
  }, [date, currentMeal.id, user]);

  useEffect(() => {
    if (user) {
      if (user.messAdmin) {
        props.setLoading(true);
        axios
          .get(
            `${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/getstudentmessdetails`,
            {
              headers: { Authorization: `BEARER ${user.messAdmin.Aa}` },
            }
          )
          .then((res) => {
            setStudentsDetails(res.data.message);
            props.setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            props.setLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleClickDate = (date) => {
    setDate(date);
  };

  const handleDiningDownload = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/messdata`, {
        headers: { Authorization: `BEARER ${user.admin.Aa}` },
        params: {
          slot: currentMeal.id,
          date,
        },
      })
      .then(({ data }) => {
        if (data.success) {
          const pre =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const excelBlob = b64toBlob(data.message, pre);
          const excelURL = URL.createObjectURL(excelBlob);
          var element = document.createElement("a");
          element.setAttribute("href", excelURL);
          element.setAttribute("download", `${currentMeal.meal}_${date}.xlsx`);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
        } else {
          props.toastCall(data.message, "warning");
        }
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.toastCall("Connection Error", "error");
        props.setLoading(false);
      });
  };

  const handleResidingListDownload = () => {
    props.setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_ADDRESS}/mess/messadmin/residinglist`,
        {
          headers: { Authorization: `BEARER ${user.admin.Aa}` },
        }
      )
      .then(({ data }) => {
        if (data.success) {
          const pre =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const excelBlob = b64toBlob(data.message, pre);
          const excelURL = URL.createObjectURL(excelBlob);
          var element = document.createElement("a");
          element.setAttribute("href", excelURL);
          element.setAttribute("download", `ResidingList.xlsx`);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
        } else {
          props.toastCall(data.message, "warning");
        }
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.toastCall("Connection Error", "error");
        props.setLoading(false);
      });
  };

  const handleLDHNumber = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/getmesslists`, {
        headers: { Authorization: `BEARER ${user.admin.Aa}` },
        params: {
          mess: "MessA",
        },
      })
      .then(({ data }) => {
        if (data.success) {
          const pre =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const excelBlob = b64toBlob(data.message, pre);
          const excelURL = URL.createObjectURL(excelBlob);
          var element = document.createElement("a");
          element.setAttribute("href", excelURL);
          element.setAttribute("download", `LDHStudentsList.xlsx`);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
        } else {
          props.toastCall(data.message, "warning");
        }
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.toastCall("Connection Error", "error");
        props.setLoading(false);
      });
  };
  const getStudentList = (messName) => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/getmesslists`, {
        headers: { Authorization: `BEARER ${user.admin.Aa}` },
        params: {
          mess: messName,
        },
      })
      .then(({ data }) => {
        if (data.success) {
          const pre =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const excelBlob = b64toBlob(data.message, pre);
          const excelURL = URL.createObjectURL(excelBlob);
          var element = document.createElement("a");
          element.setAttribute("href", excelURL);
          element.setAttribute("download", messName + 'StudentsList.xlsx');
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
        } else {
          props.toastCall(data.message, "warning");
        }
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.toastCall("Connection Error", "error");
        props.setLoading(false);
      });
  };

  const handleUDHNumber = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/getmesslists`, {
        headers: { Authorization: `BEARER ${user.admin.Aa}` },
        params: {
          mess: "MessB",
        },
      })
      .then(({ data }) => {
        if (data.success) {
          const pre =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const excelBlob = b64toBlob(data.message, pre);
          const excelURL = URL.createObjectURL(excelBlob);
          var element = document.createElement("a");
          element.setAttribute("href", excelURL);
          element.setAttribute("download", `UDHStudentsList.xlsx`);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
        } else {
          props.toastCall(data.message, "warning");
        }
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.toastCall("Connection Error", "error");
        props.setLoading(false);
      });
  };

  const handleMessNumberUpdate = (mess) => {
    props.setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/changemesslimits`,
        { limit: mess === "MessA-LDH" ? mess1 : (mess === "MessA-UDH" ? mess2 : (mess === "MessB-LDH" ? mess3 : mess4)) },
        // { limit: mess === "MessA" ? ldhNew : udhNew },
        {
          headers: { Authorization: `BEARER ${user.admin.Aa}` },
          params: {
            mess,
          },
        }
      )
      .then((res) => {
        props.setLoading(false);
        props.toastCall("Succesfully updated", "success");
        setUpdate((num) => num + 1);
        console.log(res.data.message);
      })
      .catch((err) => {
        props.setLoading(false);
        props.toastCall("Something went wrong, please try again", "error");
        console.log(err);
      });
  };

  const updateStudentDetails = (newData) => {
    props.setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_ADDRESS}/messadmin/updatestudentdetails`,
        newData,
        {
          headers: { Authorization: `BEARER ${user.messAdmin.Aa}` },
        }
      )
      .then((res) => {
        props.setLoading(false);
        setUpdate((num) => num + 1);
        props.toastCall("Succesfully updated", "success");
        console.log(res.data.message);
      })
      .catch((err) => {
        props.setLoading(false);
        props.toastCall("Something went wrong, please try again", "error");
        console.log(err);
      });
  };

  return (
    <div className="dashboard-main">
      <Grid container spacing={4}>
        <Grid item lg={12}>
          <Paper style={{ padding: "30px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div style={{ marginTop: "18px" }}>
                  <MealMenu
                    currentMeal={currentMeal}
                    setCurrentMeal={setCurrentMeal}
                  />
                </div>
                <input
                  value={date}
                  max={today}
                  style={{
                    minWidth: "150px",
                    minHeight: "30px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  type="date"
                  onChange={(e) => handleClickDate(e.target.value)}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                style={{ width: "200px" }}
                onClick={async (e) => {
                  e.preventDefault();
                  await logOut();
                  history.push("/admin");
                }}
                startIcon={
                  <img
                    style={{ height: "1rem", width: "1rem" }}
                    src={GoogleIcon}
                    alt="Sign In with Google"
                  />
                }
              >
                Log Out
              </Button>
            </div>
            {minutesSplit && minutesSplit.length !== 0 && (
              <Graph data={minutesSplit} type="minutes-split" />
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleDiningDownload()}
            >
              Download Excel dining Data of Students
            </Button>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={12}>
          <Paper style={{ padding: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              {messCounts &&
                messCounts.map((diningHall) => (
                  <div
                    style={{
                      display: "flex",

                      marginTop: "20px",
                      // alignItems: "center",
                    }} key={diningHall}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        width: "250px",
                        border: "2px solid black",
                        borderRadius: "5px",
                        marginRight: "4px",
                        height: "fit-content",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {diningHall.name} existing data : {diningHall.count}/
                      {diningHall.capacity}
                    </div>
                    <TextField
                      height="fit-content"
                      id="filled-basic"
                      label="Update Max. Capacity"
                      variant="filled"
                      value={diningHall.name === "MessA-LDH" ? mess1 : (diningHall.name === "MessA-UDH" ? mess2 : (diningHall.name === "MessB-LDH" ? mess3 : mess4))}
                      // value={diningHall.name === "MessA" ? ldhNew : udhNew}
                      onChange={(e) => {
                        if (diningHall.name === "MessA-LDH") {
                          setMess1(e.target.value);

                        } else if (diningHall.name == "MessA-UDH") {
                          setMess2(e.target.value);

                        } else if (diningHall.name === "MessB-LDH") {
                          setMess3(e.target.value);
                          // setudhNew(e.target.value);
                        } else if (diningHall.name === "MessB-UDH") {
                          setMess4(e.target.value);

                        }
                      }}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        confirm({
                          description: "This action is permanent!",
                        })
                          .then(() => {
                            handleMessNumberUpdate(diningHall.name);
                          })
                          .catch(() => { });
                      }}
                    >
                      Adjust {diningHall.name} number limit
                    </Button>

                  </div>

                ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              {
                messCounts && messCounts.map((messValue) => (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "20px" }}
                    fullWidth
                    onClick={() => getStudentList(messValue.name)}
                    key = {messValue.name}
                  >
                    excel Consolidate students registered for {messValue.name}
                  </Button>
                ))
              }
              {/* <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "20px" }}
                fullWidth
                onClick={() => handleLDHNumber()}
              >
                excel Consolidate students registered for MessA
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "20px" }}
                fullWidth
                onClick={() => handleUDHNumber()}
              >
                excel Consolidate students registered for MessB
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleResidingListDownload()}
              >
                Download Excel residing list of Students
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item lg={12}>
          <Paper style={{ padding: "20px" }}>
            <MaterialTable
              title="Students"
              icons={materialTableIcons}
              data={studentsDetails}
              columns={columns}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    Object.keys(newData).forEach((key) => {
                      if (newData[key] === "null") newData[key] = null;
                    });
                    updateStudentDetails(newData);
                    console.log(newData);
                    const dataUpdate = [...studentsDetails];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setStudentsDetails([...dataUpdate]);

                    resolve();
                    props.toastCall("Updated", "success");
                  }),
              }}
              options={{
                filtering: true,
                search: false,
              }}
            />
          </Paper>
        </Grid>
        <Grid item lg={12}>
          <Paper style={{ padding: "20px" }}>
            <div>
              <label> Start Date : </label>
              <input
                value={startDate}
                max={today}
                style={{
                  minWidth: "150px",
                  minHeight: "30px",
                  marginLeft: "10px",
                  marginRight: "20px",
                }}
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label> End Date : </label>
              <input
                value={endDate}
                max={today}
                style={{
                  minWidth: "150px",
                  minHeight: "30px",
                  marginLeft: "10px",
                  marginRight: "20px",
                }}
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            {data && data.length !== 0 && <Graph data={data} type="meals" />}
          </Paper>
        </Grid>
      </Grid>
      <ToastContainer transition={bounce} />
    </div>
  );
}

export default Dashboard;
