import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";

import Dashboard from "../Dashboard";
import { UserContext } from "../../providers/UserProvider";

function MainDashboard(props) {
  const { user } = useContext(UserContext);
  const [total, setTotal] = useState(0);
  const [dose0, setDose0] = useState(0);
  const [dose1, setDose1] = useState(0);
  const [dose2, setDose2] = useState(0);
  const [vaccineData, setVaccineData] = useState([]);
  const [vaccineNames, setVaccineNames] = useState([]);

  useEffect(() => {
    if (props.onCampus) {
      if (user) {
        if (user.admin) {
          Axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/admin/inhostelcount`, {
            headers: { Authorization: `BEARER ${user.admin.Aa}` },
          }).then((res) => {
            setTotal(res.data.message.total);
            setDose0(res.data.message.unvaccinated);
            setDose1(res.data.message.dose1);
            setDose2(res.data.message.dose2);
            setVaccineData(
              res.data.message.vaccineData.map((vaccine) => vaccine.count)
            );
            setVaccineNames(
              res.data.message.vaccineData.map((vaccine) => vaccine.vaccine)
            );
          });
        }
      }
    } else {
      Axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/count`).then((res) => {
        setTotal(res.data.message.total);
        setDose0(res.data.message.unvaccinated);
        setDose1(res.data.message.dose1);
        setDose2(res.data.message.dose2);
        setVaccineData(
          res.data.message.vaccineData.map((vaccine) => vaccine.count)
        );
        setVaccineNames(
          res.data.message.vaccineData.map((vaccine) => vaccine.vaccine)
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const doseData = [dose0, dose1, dose2];
  return (
    <Dashboard
      vaccineNames={vaccineNames}
      vaccineData={vaccineData}
      doseData={doseData}
      total={total}
    />
  );
}

export default MainDashboard;
