import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  Avatar,
  Button,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import BarcodeReader from "react-barcode-reader";
import Axios from "axios";

import GoogleIcon from "../../images/GoogleIcon.svg";
import { UserContext } from "../../providers/UserProvider";
import { logOut } from "../../services/firebase";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const useStyles2 = makeStyles((theme) => ({
  paper: {
    width: 300,
    zIndex: 1000,
  },
  backdrop: {
    width: "100%",
    height: "100%",
  },
}));

export const MealMenu = ({
  currentMeal,
  setCurrentMeal,
  messNames,
  currentMess,
  setCurrentMess,
}) => {
  const meals = ["Breakfast", "Lunch", "Snacks", "Dinner"];
  const classes = useStyles2();

  const handleClick = (meal) => {
    if (meal === "Breakfast")
      setCurrentMeal((prevState) => {
        return {
          ...prevState,
          id: 0,
          meal,
        };
      });
    else if (meal === "Lunch")
      setCurrentMeal((prevState) => {
        return {
          ...prevState,
          id: 1,
          meal,
        };
      });
    else if (meal === "Snacks")
      setCurrentMeal((prevState) => {
        return {
          ...prevState,
          id: 2,
          meal,
        };
      });
    else if (meal === "Dinner")
      setCurrentMeal((prevState) => {
        return {
          ...prevState,
          id: 3,
          meal,
        };
      });
  };

  const handleClickMess = (Mess) => {
    setCurrentMess(Mess);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
        }}
      >
        <PopupState variant="popover">
          {(popupState) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Button
                size="large"
                endIcon={<ArrowDropDownIcon />}
                variant="contained"
                color="primary"
                {...bindTrigger(popupState)}
              >
                {currentMeal.meal}
              </Button>
              <Menu
                PopoverClasses={{ paper: classes.paper }}
                onClick={popupState.close}
                {...bindMenu(popupState)}
              >
                {meals.map((Meal) => (
                  <MenuItem key={Meal} onClick={() => handleClick(Meal)}>
                    <Typography noWrap>{Meal}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </PopupState>
        {messNames && (
          <PopupState variant="popover">
            {(popupState) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <Button
                  size="large"
                  endIcon={<ArrowDropDownIcon />}
                  variant="contained"
                  color="primary"
                  {...bindTrigger(popupState)}
                  style={{ marginLeft: "10px" }}
                >
                  {currentMess}
                </Button>
                <Menu
                  PopoverClasses={{ paper: classes.paper }}
                  onClick={popupState.close}
                  {...bindMenu(popupState)}
                >
                  {messNames.map((Mess) => (
                    <MenuItem key={Mess} onClick={() => handleClickMess(Mess)}>
                      <Typography noWrap>{Mess}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
          </PopupState>
        )}
      </div>
    </div>
  );
};

function Home(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [currentMeal, setCurrentMeal] = useState({ id: 0, meal: "Breakfast" });
  const [messNames, setMessNames] = useState([]);
  const [currentMess, setCurrentMess] = useState(null);
  const [barCodeResult, setBarCodeResult] = useState(null);
  const [messData, setMessData] = useState({ success: null, message: null });

  useEffect(() => {
    if (user === null) {
      // props.toastCall("Log in first", "error");
      history.push("/messscan");
    } else if (user !== undefined) {
      if (user.mess === false) {
        props.toastCall("This user do not have access", "warning");
        history.push("/messscan");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.mess) {
        props.setLoading(true);
        Axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/mess/messnames`, {
          headers: { Authorization: `BEARER ${user.mess.Aa}` },
        })
          .then((res) => {
            setMessNames(res.data.message);
            setCurrentMess(res.data.message[0]);
            props.setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            props.setLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setBarCodeResult(null);
  }, [currentMeal]);

  useEffect(() => {
    if (user) {
      if (user.mess) {
        if (barCodeResult !== null) {
          Axios.post(
            `${process.env.REACT_APP_SERVER_ADDRESS}/mess/scan`,
            {
              email: `${barCodeResult.toLowerCase()}@iith.ac.in`,
              slot: currentMeal.id,
              messname: currentMess,
            },
            {
              headers: { Authorization: `BEARER ${user.mess.Aa}` },
            }
          )
            .then(async (res) => {
              setMessData((prevState) => {
                return {
                  ...prevState,
                  success: res.data.success,
                  message: res.data.message,
                };
              });
            })
            .catch((err) => props.toastCall(err, "error"));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCodeResult]);

  const handleScan = (data) => {
    console.log(data);
    setBarCodeResult(data);
  };

  const handleError = (err) => {
    console.log(err);
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LocalDiningIcon />
      </Avatar>
      {user && <Typography>Dining Scan</Typography>}
      <div style={{ padding: "15px" }} />
      <form className={classes.form} noValidate>
        {user ? (
          <div>
            <MealMenu
              currentMeal={currentMeal}
              setCurrentMeal={setCurrentMeal}
              messNames={messNames}
              currentMess={currentMess}
              setCurrentMess={setCurrentMess}
            />
            <BarcodeReader onError={handleError} onScan={handleScan} />
            <div style={{ textAlign: "center" }}>
              {barCodeResult === null &&
                "Please select the mess name, meal and scan the barcode to display the result here"}
              {!messData.success && (
                <p style={{ fontWeight: "600", fontSize: "18px" }}>
                  {barCodeResult}
                </p>
              )}
            </div>
            {barCodeResult ? (
              messData.success !== null && messData.success ? (
                <div className="l-items" style={{ textAlign: "center" }}>
                  <List>
                    <ListItem>
                      <span className="l-item">Name</span> :{" "}
                      <span>{messData.message.Name}</span>
                    </ListItem>
                    <ListItem>
                      <span className="l-item">Roll Number</span> :{" "}
                      <span>{barCodeResult}</span>
                    </ListItem>
                    <ListItem>
                      <span className="l-item">Dining Hall</span> :{" "}
                      <span>{messData.message.Mess}</span>
                    </ListItem>
                    {messData.message.Slot && (
                      <ListItem>
                        <span className="l-item">Slot</span> :{" "}
                        <span>{messData.message.Slot}</span>
                      </ListItem>
                    )}
                    {messData.message.count && (
                      <ListItem>
                        <span className="l-item">Count for Slot</span> :{" "}
                        <span>{messData.message.count}</span>
                      </ListItem>
                    )}
                  </List>
                </div>
              ) : (
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  {messData.message}
                </p>
              )
            ) : (
              <p></p>
            )}
          </div>
        ) : (
          <p>Please log in first</p>
        )}
      </form>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
        onClick={async (e) => {
          e.preventDefault();
          if (user.mess) {
            console.log("Hello");
            await logOut();
            history.push("/messscan");
          } else history.push("/messscan");
        }}
        startIcon={
          <img
            style={{ height: "1rem", width: "1rem" }}
            src={GoogleIcon}
            alt="Sign In with Google"
          />
        }
      >
        {user ? "Log Out" : "Log In"}
      </Button>
    </div>
  );
}

export default Home;
