import { Button, Grid, Paper } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { UserContext } from "../../providers/UserProvider";
import { logOut } from "../../services/firebase";
import GoogleIcon from "../../images/GoogleIcon.svg";
import MainDashboard from "../../components/Dashboards/MainDashboard";
import OnCampusHostel from "./OnCampusHostelWise";
import OnCampusBatch from "./OnCampusBatchWise";
import ByDate from "./ByDate";
import axios from "axios";
import { b64toBlob } from "../../components/scripts";

function Dashboard(props) {
  const history = useHistory();
  const { user } = useContext(UserContext);

  const handleDownload = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/admin/excel`, {
        headers: { Authorization: `BEARER ${user.admin.Aa}` },
      })
      .then(({ data }) => {
        if (data.success) {
          const pre =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const excelBlob = b64toBlob(data.message, pre);
          const excelURL = URL.createObjectURL(excelBlob);
          var element = document.createElement("a");
          element.setAttribute("href", excelURL);
          element.setAttribute("download", "download.xlsx");
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
        } else {
          props.toastCall(data.message, "warning");
        }
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.toastCall("Connection Error", "error");
        props.setLoading(false);
      });
  };

  const handleDownloadDose = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/admin/seconddoseexcel`, {
        headers: { Authorization: `BEARER ${user.admin.Aa}` },
      })
      .then(({ data }) => {
        if (data.success) {
          const pre =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const excelBlob = b64toBlob(data.message, pre);
          const excelURL = URL.createObjectURL(excelBlob);
          var element = document.createElement("a");
          element.setAttribute("href", excelURL);
          element.setAttribute("download", "download.xlsx");
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
        } else {
          props.toastCall(data.message, "warning");
        }
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.toastCall("Connection Error", "error");
        props.setLoading(false);
      });
  };

  useEffect(() => {
    if (user === null) {
      // props.toastCall("Log in first", "error");
      history.push("/admin");
    } else if (user !== undefined) {
      if (user.admin === false) {
        props.toastCall("This user do not have access", "warning");
        history.push("/admin");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="dashboard-main">
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Paper className="dashboard-paper">
            <h3>Total Students Data</h3>
            <MainDashboard />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className="dashboard-paper">
            <h3>On Campus Students Data</h3>
            <MainDashboard onCampus={true} />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className="dashboard-paper">
            <h3>On Campus Hostel Wise Students Data</h3>
            <OnCampusHostel />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className="dashboard-paper">
            <h3>Batch Wise Students Data</h3>
            <OnCampusBatch setLoading={props.setLoading} />
          </Paper>
        </Grid>
        <Grid item lg={12}>
          <ByDate />
        </Grid>
        <Grid item lg={12}>
          <Paper style={{ padding: "30px" }}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleDownload()}
                >
                  Download Excel Data of Students
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleDownloadDose()}
                >
                  Second dose due date excel
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ width: "50%" }}
                  onClick={async (e) => {
                    e.preventDefault();
                    await logOut();
                    history.push("/admin");
                  }}
                  startIcon={
                    <img
                      style={{ height: "1rem", width: "1rem" }}
                      src={GoogleIcon}
                      alt="Sign In with Google"
                    />
                  }
                >
                  Log Out
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
