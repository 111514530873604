import React, { useContext, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { UserContext } from "../../providers/UserProvider";
import { Button, Paper } from "@material-ui/core";
import { Route, useHistory } from "react-router";
import { logOut, signInWithGoogle } from "../../services/firebase";
import GoogleIcon from "../../images/GoogleIcon.svg";
import { ToastContainer } from "react-toastify";
import { bounce } from "../../App";
import Home from "./Home";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignInPage(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      if (user.admin !== undefined) {
        const { admin } = user;
        if (admin === false) {
          props.toastCall("This user do not have access", "warning");
          logOut();
        } else {
          history.push("/adminscan/home");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="adminbarcode-main">
      <Paper className="adminscan-paper" elevation={24}>
        <Route path="/adminscan" exact={true}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <PermIdentityIcon />
            </Avatar>
            <Typography>Log in with google</Typography>
            <div style={{ padding: "15px" }} />
            <form className={classes.form} noValidate>
              <center>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  className={classes.submit}
                  onClick={async (e) => {
                    e.preventDefault();
                    user ? await logOut() : await signInWithGoogle();
                  }}
                  startIcon={
                    <img
                      style={{ height: "1rem", width: "1rem" }}
                      src={GoogleIcon}
                      alt="Sign In with Google"
                    />
                  }
                >
                  {user ? "Log Out" : "Log In"}
                </Button>
              </center>
            </form>
          </div>
        </Route>
        <Route path="/adminscan/home" exact={true}>
          <Home toastCall={props.toastCall} />
        </Route>
        <ToastContainer transition={bounce} />
      </Paper>
    </div>
  );
}

export default SignInPage;
