import React, { Suspense, lazy, useState, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";

import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, cssTransition } from "react-toastify";

import "./App.css";
import AdminPage from "./AllPages/AdminPages/index";
import AdminBarCode from "./AllPages/AdminBarCodePages/Login";
import MessScan from "./AllPages/MessScanPages/Login";
import MessAdmin from "./AllPages/MessAdminPages/Login";

import { UserContext } from "./providers/UserProvider";
import Dashboard from "./components/Dashboards/MainDashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 112px)",
    flexGrow: 1,
    //paddingTop: theme.spacing(3),
    //paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: { minHeight: "calc(100vh - 128px)" },
  },
  image: {
    backgroundImage: "url('./images/IIT_hyderabad.jpeg')",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
});

const Pages = [
  {
    View: lazy(() => import("./AllPages/SignInPage")),
    path: "/",
    exact: "true",
  },
  {
    View: lazy(() => import("./AllPages/VaccinationStatus")),
    path: "/vaccinationstatus",
    exact: "true",
  },
  {
    View: lazy(() => import("./AllPages/VaccineData")),
    path: "/vaccinedata",
    exact: "true",
  },
  {
    View: lazy(() => import("./AllPages/VerificationOptions")),
    path: "/options",
    exact: "true",
  },
  // {
  //   View: lazy(() => import("./AllPages/GetOtp")),
  //   path: "/getotp",
  //   exact: "true",
  // },
  // {
  //   View: lazy(() => import("./AllPages/EnterOTPPage")),
  //   path: "/enterOTP",
  //   exact: "true",
  // },
  // {
  //   View: lazy(() => import("./AllPages/BeneficiaryIdPage")),
  //   path: "/beneficiaryid",
  //   exact: "true",
  // },
];

export const toastCall = (message, severity) => {
  switch (severity) {
    case "success":
      toast.success(message, {
        transition: bounce,
        position: "bottom-left",
      });
      break;

    case "info":
      toast.info(message, {
        transition: bounce,
        position: "bottom-left",
      });
      break;

    case "error":
      toast.error(message, {
        transition: bounce,
        position: "bottom-left",
      });
      break;

    case "warning":
      toast.warning(message, {
        transition: bounce,
        position: "bottom-left",
      });
      break;

    default:
      toast.dark(message, {
        transition: bounce,
        position: "bottom-left",
      });
      break;
  }
};

const App = () => {
  const classes = useStyles();
  const { loading, setLoading } = useContext(UserContext);
  const [txnId, settxnId] = useState(null);
  const [token, setToken] = useState(null);
  const [vaccineData, setVaccineData] = useState(null);
  const [mobnum, setMobNum] = useState(null);
  const [open, setOpen] = useState(false);

  const Loading = (
    <div className={classes.content}>
      <Backdrop open>
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </div>
  );

  return (
    <div>
      <Suspense fallback={Loading}>
        <Switch>
          <Route path="/admin" exact={false}>
            <AdminPage setLoading={setLoading} toastCall={toastCall} />
          </Route>
          <Route path="/adminscan" exact={false}>
            <AdminBarCode setLoading={setLoading} toastCall={toastCall} />
          </Route>
          <Route path="/messscan" exact={false}>
            <MessScan setLoading={setLoading} toastCall={toastCall} />
          </Route>
          <Route path="/messadmin" exact={false}>
            <MessAdmin setLoading={setLoading} toastCall={toastCall} />
          </Route>
          <div>
            <CssBaseline />
            <Dialog maxWidth="lg" fullWidth open={open}>
              <DialogTitle>Dashboard</DialogTitle>
              <DialogContent>
                <Dashboard />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
              </DialogActions>
            </Dialog>
            <div className="main-div">
              <Backdrop
                className={classes.backdrop}
                unmountOnExit
                style={{ color: "black", zIndex: 1000 }}
                open={loading}
              >
                <CircularProgress style={{ color: "white" }} />
              </Backdrop>
              <Paper className="main-paper">
                {Pages.map((Page, key) => (
                  <Route key={key} path={Page.path} exact={true}>
                    <Page.View
                      txnId={txnId}
                      settxnId={settxnId}
                      token={token}
                      setToken={setToken}
                      vaccineData={vaccineData}
                      setVaccineData={setVaccineData}
                      mobnum={mobnum}
                      setMobNum={setMobNum}
                      toastCall={toastCall}
                      setLoading={setLoading}
                    />
                  </Route>
                ))}
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                    marginTop: "-30px",
                  }}
                >
                  <div>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => setOpen(true)}
                      startIcon={<DashboardIcon />}
                    >
                      Dashboard
                    </Button>
                  </div>
                </div>
              </Paper>
            </div>
            <ToastContainer transition={bounce} />
          </div>
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
