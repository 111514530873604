import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import SchoolIcon from "@material-ui/icons/School";
import { makeStyles } from "@material-ui/core/styles";

import { Pie } from "react-chartjs-2";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

function Dashboard(props) {
  const classes = useStyles();

  const vaccine = {
    labels: props.vaccineNames,
    datasets: [
      {
        label: "Vaccination Data IITH",
        backgroundColor: [
          "#ff751a",
          "#00A6B4",
          "#FF0000",
          "#52D726",
          "#C9DE00",
          "#7CDDDD",
        ],
        hoverBackgroundColor: [
          "#803300",
          "#003350",
          "#800000",
          "#328217",
          "#4B5000",
          "#2aa2a2",
        ],
        data: props.vaccineData,
      },
    ],
  };

  const doses = {
    labels: ["Unvaccinated", "Dose 1", "Dose 2"],
    datasets: [
      {
        label: "Vaccination Data IITH",
        backgroundColor: ["#ff751a", "#00A6B4", "#C9DE00"],
        hoverBackgroundColor: ["#803300", "#003350", "#4B5000"],
        data: props.doseData,
      },
    ],
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
            elevation={5}
          >
            <div>
              <h4>Total number of students</h4>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Avatar className={classes.avatar}>
                <SchoolIcon />
              </Avatar>
              <h3>{props.total}</h3>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <h3 style={{ marginTop: "30px" }}>Students vaccinated with :</h3>
      <div className="dashboard-pie-div">
        <div className="dashboard-pie">
          <Pie
            data={doses}
            options={{
              title: {
                display: true,
                text: "Vaccination data IITH",
                fontSize: 20,
              },
              legend: {
                display: true,
                position: "left",
              },
            }}
          />
        </div>
        <div className="dashboard-pie">
          <Pie
            data={vaccine}
            options={{
              title: {
                display: true,
                text: "Vaccination data IITH",
                fontSize: 20,
              },
              legend: {
                display: true,
                position: "left",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
