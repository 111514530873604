import axios from "axios";
import React, { useState, useEffect, createContext } from "react";

import { auth } from "../services/firebase";

export const UserContext = createContext({
  // user: { student: null, admin: null, adminbarcode: null },
  user: null,
  loading: null,
  setLoading: () => { },
});
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [user, setuser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    auth.onAuthStateChanged(async (User) => {
      if (User) {
        setLoading(true);
        axios
          .get(`${process.env.REACT_APP_SERVER_ADDRESS}/accesslevels`, {
            headers: {
              Authorization: `BEARER ${User.Aa}`,
            },
          })
          .then((res) => {
            // const { displayName, email, Aa } = User;
            let userNew = {
              student: User,
              admin: false,
              messAdmin: false,
              mess: false,
            };

            if (res.data.message.admin) {
              userNew.admin = User;
            } else userNew.admin = false;

            if (res.data.message.messAdmin) {
              userNew.messAdmin = User;
            } else userNew.messAdmin = false;

            if (res.data.message.mess) {
              userNew.mess = User;
            } else userNew.mess = false;

            setuser(userNew);
            setLoading(false);
          });
      } else {
        setuser(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <UserContext.Provider value={{ user, loading, setLoading }}>
      {props.children}
    </UserContext.Provider>
  );
};
