import React, { useContext, useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import Axios from "axios";

import Dashboard from "../../components/Dashboard";
import { useHistory } from "react-router";
import { UserContext } from "../../providers/UserProvider";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 300,
    zIndex: 1000,
  },
  backdrop: {
    width: "100%",
    height: "100%",
  },
}));

const BatchMenu = (Props) => {
  const classes = useStyles();

  const handleClickBatch = (Batch) => {
    Props.setCurrentBatch(Batch);
  };

  const handleClickProgram = (Program) => {
    Props.setCurrentProgram(Program);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
        }}
      >
        <PopupState variant="popover">
          {(popupState) => (
            <div>
              <Button
                size="large"
                endIcon={<ArrowDropDownIcon />}
                variant="contained"
                color="primary"
                style={{ marginRight: "10px" }}
                {...bindTrigger(popupState)}
              >
                {Props.currentProgram}
              </Button>
              <Menu
                PopoverClasses={{ paper: classes.paper }}
                onClick={popupState.close}
                {...bindMenu(popupState)}
              >
                {Props.degrees.map((Page) => (
                  <MenuItem key={Page} onClick={() => handleClickProgram(Page)}>
                    <Typography noWrap>{Page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </PopupState>
        <PopupState variant="popover">
          {(popupState) => (
            <div>
              <Button
                size="large"
                endIcon={<ArrowDropDownIcon />}
                variant="contained"
                color="primary"
                {...bindTrigger(popupState)}
              >
                {Props.currentBatch}
              </Button>
              <Menu
                PopoverClasses={{ paper: classes.paper }}
                onClick={popupState.close}
                {...bindMenu(popupState)}
              >
                {Props.batches.map((Page) => (
                  <MenuItem key={Page} onClick={() => handleClickBatch(Page)}>
                    <Typography noWrap>{Page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </PopupState>
      </div>
    </div>
  );
};

function OnCampusBatch(props) {
  const classes = useStyles();
  const [degrees, setDegrees] = useState([]);
  const [degreeBatches, setDegreeBatches] = useState([]);
  const [currentProgram, setCurrentProgram] = useState(null);
  const [currentBatch, setCurrentBatch] = useState(null);
  const [total, setTotal] = useState(0);
  const [doseData, setDoseData] = useState([]);
  const [vaccineData, setVaccineData] = useState([]);
  const [vaccineNames, setVaccineNames] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (user === null) {
      // props.toastCall("Log in first", "error");
      history.push("/admin");
    } else if (user !== undefined) {
      if (user.admin === false) {
        props.toastCall("This user do not have access", "warning");
        history.push("/admin");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.admin) {
        setLoading(true);
        Axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/admin/degrees`, {
          headers: { Authorization: `BEARER ${user.admin.Aa}` },
        })
          .then((res1) => {
            setDegrees(res1.data.message);
            setCurrentProgram(res1.data.message[0]);
          })
          .catch((err1) => {
            console.log(err1);
            setLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.admin && currentProgram) {
        setLoading(true);
        Axios.get(
          `${process.env.REACT_APP_SERVER_ADDRESS}/admin/batches/${currentProgram}`,
          {
            headers: { Authorization: `BEARER ${user.admin.Aa}` },
          }
        )
          .then((res) => {
            setDegreeBatches(res.data.message.map((batch) => `20${batch}`));
            setCurrentBatch(`20${res.data.message[0]}`);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgram]);

  useEffect(() => {
    if (user) {
      if (user.admin && currentProgram && currentBatch) {
        setLoading(true);
        Axios.get(
          `${
            process.env.REACT_APP_SERVER_ADDRESS
          }/admin/batchwisedata/${currentProgram}/${parseInt(
            currentBatch.toString().slice(2, 4)
          )}`,
          {
            headers: { Authorization: `BEARER ${user.admin.Aa}` },
          }
        )
          .then((res) => {
            const data = res.data.message;
            setTotal(data.total);
            setDoseData([data.unvaccinated, data.dose1, data.dose2]);
            setVaccineData(data.vaccineData.map((vaccine) => vaccine.count));
            setVaccineNames(data.vaccineData.map((vaccine) => vaccine.vaccine));
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBatch]);

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        unmountOnExit
        style={{ color: "black", zIndex: 1000 }}
        open={loading}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      <BatchMenu
        currentBatch={currentBatch}
        setCurrentBatch={setCurrentBatch}
        currentProgram={currentProgram}
        setCurrentProgram={setCurrentProgram}
        degrees={degrees}
        batches={degreeBatches}
      />
      <Dashboard
        vaccineNames={vaccineNames}
        vaccineData={vaccineData}
        doseData={doseData}
        total={total}
      />
    </>
  );
}

export default OnCampusBatch;
